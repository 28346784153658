.building-specification-section {
  @include grid-gutter(.625rem);
  margin-bottom: 6.25rem;

  .container {
    position: relative;
    left: -4.375rem;
  }

  .row {
    .row {
      padding-left: 4.6875rem;

      [class*="col-"] {
        display: flex;

        figure {
          width: 100%;
        }
      }
    }
  }

  // Images
  figure {
    display: block;
    margin-bottom: .625rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      opacity: 0;
    }
  }

  .image-1 {
    margin-right: 4.375rem;
  }

  // Text
  h2 {
    max-width: 26.25rem;
    margin: 4.5rem 0 2.5rem;
    color: $black;
  }

  .btn {
    width: 17.5rem;
    max-width: 100%;
    color: inherit;
    text-align: left;
  }

  .text-2 {
    padding-left: 3.75rem;
  }

  @include media-breakpoint-down(xl) {
    .container {
      left: auto;

      & > .row {
        margin-left: -4.6875rem;
      }
    }

    h2 {
      margin-top: 2.5rem;
    }
  }

  @include media-breakpoint-down(lg) {
    .container {
      & > .row {
        margin-left: -1.25rem;
      }
    }

    .image-1 {
      min-height: 21rem;
    }

    .text-1 {
      margin-left: -2.5rem;
    }

    .text-2 {
      padding-left: 1.875rem;
    }
  }

  @include media-breakpoint-down(md) {
    .row {
      .row {
        padding-left: .9375rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 5rem;

    .container {
      & > .row {
        margin-left: 0;
      }
    }

    .row {
      .row {
        padding-left: 0;
      }
    }

    .image-1 {
      min-height: 0;
      margin-right: 0;
    }

    .text-1 {
      margin-left: 0;
      margin-bottom: 3.5rem;
    }

    .text-2 {
      padding-left: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      margin-bottom: 1.5rem;
    }

    .btn {
      width: auto;
    }
  }
}


.specification-list {
  @include list-unstyled();
  margin: 0 -1.875rem;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 0 0 auto;
    display: flex;
    width: 50%;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    text-align: center;
    text-transform: uppercase;
    font-family: $font-family-secondary;
    font-size: 1.25rem;
    font-weight: $font-weight-light;
    color: $primary;

    span {
      display: block;
      width: 100%;
      padding: 1.875rem 0;
      border-bottom: .375rem solid $primary;
    }

    strong {
      display: block;
      font-size: 2.5rem;
      font-weight: $font-weight-normal;
      line-height: 1.25em;
    }

    &:nth-child(1),
    &:nth-child(2) {
      span {
        border-top: .375rem solid $primary;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    margin: 0 -1.25rem;

    li {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 3rem;
  }

  @include media-breakpoint-down(xs) {
    margin-left: -.5rem;
    margin-right: -.5rem;

    li {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
}
