.building-reception-section {
  @include grid-gutter(.625rem);
  margin-bottom: 6.25rem;

  .container {
    position: relative;
    right: -4.375rem;
  }

  .row {
    padding-left: 4.6875rem;
  }

  // Images
  figure {
    display: block;
    margin-bottom: .625rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      opacity: 0;
    }
  }

  .image-3 {
    margin-left: 2.1875rem;
  }

  // Text
  h2 {
    margin: 3.75rem 0;
    color: $black;
  }

  p {
    max-width: 22.5rem;
    margin: 0 0 8.5rem;
    font-weight: $font-weight-light;
  }

  blockquote {
    max-width: 21.25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .text-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  .text-2 {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  @include media-breakpoint-down(xl) {
    .container {
      right: auto;

      & > .row {
        margin-right: -4.6875rem;
      }
    }

    .image-1 {
      margin-right: -4.375rem;
    }

    .text-1 {
      padding-left: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .container {
      & > .row {
        margin-right: -1.25rem;
      }
    }

    .row {
      padding-left: 0;
    }

    .image-1 {
      margin-right: -.9375rem;
    }

    p {
      margin-bottom: 5rem;
    }

    .text-1 {
      padding: 0 1.875rem 0 0;
    }
  }

  @include media-breakpoint-down(md) {
    h2 {
      margin: 2.5rem 0;
    }

    p {
      margin: 0 0 3rem;
    }

    .text-2 {
      padding-left: 1.875rem;
      padding-right: 2.5rem;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 5rem;

    .container {
      & > .row {
        margin-right: -.3125rem;
      }
    }

    .image-1 {
      margin-right: 0;
    }

    .image-3 {
      margin-left: 0;
    }

    .text-1 {
      padding: 0;
      display: block;
    }

    .text-2 {
      padding: 0;
    }

    p {
      margin: 0 0 2.5rem;
      max-width: 100%;
    }

    .blockquote {
      margin: 2.5rem auto 0;
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      margin-bottom: 1rem;
    }
  }
}
