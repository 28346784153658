.space-plans-fancybox {
  .fancybox-close-small {
    display: none;
  }
}

.accommodation-space-modal {
  margin: 0;
  background: none;

  .space-modal-container {
    display: flex;
    padding: 2rem 4.375rem 1rem;
    background-color: $body-bg;
  }

  .column-1 {
    flex: 0 0 auto;
    max-width: 16.25rem;
    padding-top: 2.375rem;
    padding-bottom: 3.375rem;

    h2 {
      margin: 0 0 3.5rem;
      font-size: 2.5rem;
      letter-spacing: .05em;
      line-height: 1.25em;
    }

    h3 {
      margin: 2.5rem 0 0;
      font-size: 1.375rem;
      letter-spacing: .022727273em;
      color: $primary;
    }

    h4 {
      margin: 0 0 .5rem;
      font-size: .875rem;
      letter-spacing: .014285714em;
      line-height: 1em;
    }

    .area-size {
      margin: 0 0 1.75rem;
      display: block;
      font-size: 1.25rem;
      font-weight: $font-weight-light;
    }

    p {
      font-weight: $font-weight-light;

      small {
        font-weight: inherit;
      }
    }

    table {
      width: 100%;
      margin: 0 0 3rem;
      font-size: .75rem;
      font-weight: $font-weight-light;

      td {
        padding: .5rem .625rem;
        border-bottom: 1px solid rgba($black, .25);

        &:last-child {
          text-align: right;
          font-weight: $font-weight-bold;
        }
      }
    }

    .btn {
      &:last-child {
        margin-top: 1.75rem;
      }
    }
  }

  .column-2 {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4.375rem;
    padding-right: 4.375rem;

    figure {
      margin: 0;

      img {
        width: 100%;
        height: auto;
        max-width: 560px;
        max-height: 100%;
        // mix-blend-mode: multiply;
      }
    }
  }

  .column-3 {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 2.375rem;
    padding-bottom: 3.375rem;

    .n-round {
      display: block;
      width: 2.5rem;
      height: auto;
      margin-bottom: 2rem;
    }

    h4 {
      margin: 0 0 1.25rem;
      font-size: .875rem;
      letter-spacing: .014285714em;
      line-height: 1em;
    }

    ul {
      @include list-unstyled;
      margin: 0 0 3.75rem;
      font-size: .875rem;
      font-weight: $font-weight-light;
      line-height: 1rem;

      li {
        margin-bottom: 1.1rem;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          margin-right: .5rem;
          border: 1px solid $grey-5;
          vertical-align: top;
        }
      }
    }

    .zoom-link {
      display: inline-block;
      font-size: .75rem;
      font-family: $font-family-secondary;
      color: inherit;
      letter-spacing: .058333333em;
      text-transform: uppercase;
      text-decoration: none;
      line-height: .875rem;

      svg {
        display: inline-block;
        width: .875rem;
        height: .875rem;
        margin-right: .6rem;
        vertical-align: top;
        fill: currentColor;
      }

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .space-modal-container {
      padding-left: 3.4375rem;
      padding-right: 3.4375rem;
    }
  }

  @include media-breakpoint-down(md) {
    .space-modal-container {
      display: block;

      .column-1 {
        max-width: 100%;
        margin-bottom: 5rem;
        padding-bottom: 0;
      }

      .column-2 {
        padding: 0;

        img {
          width: 100%;
          height: auto;
        }
      }

      .column-3 {
        padding-top: 0;

        ul {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 2.5rem;

          li {
            flex: 0 0 auto;
            width: percentage(1 / 4);
          }
        }

        .zoom-link {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .space-modal-container {
      padding-left: .9375rem;
      padding-right: .9375rem;

      .column-3 {
        ul {
          li {
            width: percentage(1 / 2);
          }
        }
      }
    }
  }
}
