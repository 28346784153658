.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: $z-index-menu;
  visibility: hidden;
  transition: visibility 0s linear .75s;

  .menu-open & {
    visibility: visible;
    transition: visibility 0s linear;
  }

  figure {
    flex: 1 1 auto;
    margin: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: translate3d(0, -100%, 0);
    transition: transform .75s cubic-bezier(0.22, 0.61, 0.36, 1);
    will-change: transform;

    .menu-open & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    img {
      display: none;
    }
  }

  .container {
    flex: 0 0 auto;
    padding: 4.375rem .9375rem 2rem;
    text-align: center;
    background-color: $primary;
    transform: translate3d(0, 100%, 0);
    transition: transform .75s cubic-bezier(0.22, 0.61, 0.36, 1);
    will-change: transform;

    .menu-open & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  ul {
    @include list-unstyled;
    display: inline-flex;
    justify-content: center;
    margin: 0 auto 4.375rem;
    padding-bottom: 2.25rem;
    border-bottom: 2px solid $secondary;

    li {
      margin-right: 1.75rem;

      &:last-child {
        margin-right: 0;
      }

      a {
        position: relative;
        display: block;
        padding: 0 .9375rem;
        font-family: $font-family-secondary;
        font-size: 1.25rem;
        line-height: 1.2em;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        text-decoration: none;
        color: inherit;

        &:hover,
        &:focus {
          color: $white;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -2.375rem;
          width: 100%;
          height: 4px;
          background-color: $white;
          opacity: 0;
          transition: $transition-base;
        }
      }

      &.active {
        a {
          color: $white;
          cursor: default;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  h5 {
    margin: 0 0 2.5rem;
    font-size: 1rem;
    line-break: 1.2em;
    letter-spacing: .0625em;
    font-weight: $font-weight-base;
  }

  .contacts-row {
    display: flex;
    justify-content: center;
  }

  article {
    display: inline-block;
    text-align: left;
    margin-right: 4.375rem;

    &:last-child {
      margin-right: 0;
    }

    h3 {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: 0;
      overflow: hidden;
      opacity: 0;
    }

    .logo {
      display: block;
      width: auto;
      height: 1.5rem;
      margin: 0 0 1.6rem;
    }

    .tel {
      display: inline-block;
      margin-bottom: 0;
      font-weight: $font-weight-bold;
      font-size: .875rem;
      color: inherit;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  .copyright {
    margin: 6.25rem 0 0;
    font-family: $font-family-secondary;
    font-size: .75rem;
    letter-spacing: .083333333em;
    line-height: 1.5em;
    color: $black;
    text-transform: uppercase;
    opacity: .35;

    span {
      margin: .9375rem;
    }

    a {
      color: inherit;
    }
  }

  @media (max-height: 900px) {
    .container {
      padding-top: 3.5rem;
      padding-bottom: .9375rem;
    }

    ul {
      margin: 0 auto 3.5rem;
      padding-bottom: 2.25rem;
    }

    .copyright {
      margin-top: 3.5rem;
    }
  }

  @media (max-height: 575px) {
    aside {
      display: none;
    }

    ul {
      margin: 0 !important;
    }

    .container {
      padding-top: 2.5rem;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  @include media-breakpoint-down(lg) {
    // padding-top: 8.125rem;

    figure {
      display: none;
    }

    .container {
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: auto;
    }
  }

  @include media-breakpoint-down(md) {
    ul {
      margin: 0 auto 4.375rem;
      padding-bottom: 1.5rem;

      li {
        margin-right: .9375rem;

        &:last-child {
          margin-right: 0;
        }

        a {
          padding: 0 .9375rem;
          font-size: 1rem;
          line-height: 1.2em;

          &:after {
            height: 2px;
            bottom: -1.625rem;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    ul {
      display: block;
      margin-bottom: 2.5rem;
      padding: .9375rem 0;
      border-top: 2px solid $secondary;

      li {
        margin-right: 0;
        margin-bottom: .9375rem;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          padding: 0 .9375rem;
          font-size: 1rem;
          line-height: 1.2em;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    // padding-top: 6.875rem;

    .container {
      padding-bottom: 2.5rem;
    }

    .contacts-row {
      display: flex;
      justify-content: space-around;
    }

    article {
      margin-right: 2.5rem;
    }

    .copyright {
      display: none;
    }
  }
}
