.accommodation-floor-plan-section {
  margin-bottom: 6.25rem;

  .floor-plan-slide {
    width: 100%;
  }

  .floor-plan-container {
    display: flex;
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .column-1 {
    flex: 0 0 auto;
    max-width: 17.5rem;

    h2 {
      margin: 1.5rem 0 2rem;
      font-size: 2.5rem;
      letter-spacing: .05em;
    }

    h4 {
      margin: 0 0 .5rem;
      font-size: .875rem;
      letter-spacing: .014285714em;
      line-height: 1em;
    }

    .area-size {
      margin: 0 0 1.75rem;
      display: block;
      font-size: 1.25rem;
      font-weight: $font-weight-light;
    }

    p {
      font-weight: $font-weight-light;

      small {
        font-weight: inherit;
      }
    }

    .btn {
      &:last-child {
        margin-top: 1.75rem;
      }
    }
  }

  .column-2 {
    align-self: center;
    flex: 1 1 auto;
    padding-left: 4.375rem;
    padding-right: 4.375rem;

    figure {
      width: 100%;
      margin: 0;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .column-3 {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;

    .n-round {
      display: block;
      width: 2.5rem;
      height: auto;
      margin-bottom: 2rem;
    }

    h4 {
      margin: 0 0 1.25rem;
      font-size: .875rem;
      letter-spacing: .014285714em;
      line-height: 1em;
    }

    ul {
      @include list-unstyled;
      margin: 0 0 3.75rem;
      font-size: .875rem;
      font-weight: $font-weight-light;
      line-height: 1rem;

      li {
        margin-bottom: 1.1rem;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          margin-right: .5rem;
          border: 1px solid $grey-5;
          vertical-align: top;
        }
      }
    }

    .zoom-link {
      display: inline-block;
      font-size: .75rem;
      font-family: $font-family-secondary;
      color: inherit;
      letter-spacing: .058333333em;
      text-transform: uppercase;
      text-decoration: none;
      line-height: .875rem;
      margin-bottom: 2.5rem;

      svg {
        display: inline-block;
        width: .875rem;
        height: .875rem;
        margin-right: .6rem;
        vertical-align: top;
        fill: currentColor;
      }

      &:hover,
      &:focus {
        color: $primary;
      }
    }

    .floor-image {
      position: relative;
      display: block;
      width: 100%;
      margin: auto 0 4rem;
      padding: percentage(1060 / 1120) 0 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        max-width: 100px;

        &.floor {
          mix-blend-mode: multiply;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .flickity-prev-next-button.previous {
      left: 0;
    }

    .flickity-prev-next-button.next {
      right: 0;
    }

    .floor-plan-container {
      padding-left: 3.4375rem;
      padding-right: 3.4375rem;
    }
  }

  @include media-breakpoint-down(md) {
    .floor-plan-container {
      display: block;
      flex-direction: column;

      .column-1 {
        max-width: 100%;
        margin-bottom: 5rem;
      }

      .column-2 {
        padding: 0;

        img {
          width: 100%;
          height: auto;
        }
      }

      .column-3 {
        ul {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 2.5rem;

          li {
            flex: 0 0 auto;
            width: percentage(1 / 4);
          }
        }

        .zoom-link {
          margin-bottom: 0;
        }

        .floor-image {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .flickity-prev-next-button {
      position: static;
      transform: translateY(0);
      margin-top: 2.5rem;
      margin-right: .9375rem;
    }

    .floor-plan-container {
      padding-left: 0;
      padding-right: 0;

      .column-3 {
        ul {
          li {
            width: percentage(1 / 2);
          }
        }
      }
    }
  }
}
