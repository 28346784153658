/*! Flickity v2.0.10
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;

  &:focus {
    outline: none;
  }
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  .flickity-viewport {
    cursor: move;
    cursor: grab;

    &.is-pointer-down {
      cursor: grabbing;
    }
  }
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0;
  padding: 0;
  border: 3px solid $secondary;
  background: none;
  cursor: pointer;
  transform: translateY(-50%);
  transition: $transition-base;

  &:hover,
  &:focus {
    outline: none;
    background-color: $secondary;
    color: $white;
  }

  &.previous {
    left: -1.25rem;
  }

  &.next {
    right: -1.25rem;
  }
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 0;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 0;
}

.flickity-prev-next-button:disabled {
  opacity: 0.25;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 25%;
  top: 25%;
  width: 50%;
  height: 50%;
}

.flickity-prev-next-button .arrow {
  fill: currentColor;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
