.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  font-family: $font-family-secondary;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .083333333em;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  transition: $transition-base;
  @include button-size($btn-padding-y, $btn-padding-x, 1.125rem, $btn-line-height, $btn-border-radius);

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  @include media-breakpoint-down(xs) {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, 1rem, $btn-line-height-sm, $btn-border-radius-sm);
    border-width: 3px;
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


// Alternate buttons
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


// Link buttons
.btn-link {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  color: $link-color;
  background-color: transparent;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    bottom: .625em;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transition: $transition-base;
  }

  @include hover {
    color: $link-hover-color;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;

    &:after {
      transform: translateY(5px);
    }
  }

  &:focus,
  &.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  @include media-breakpoint-down(xs) {
    &:after {
      bottom: .25em;
    }
  }
}


// Button Icon
.btn-icon {
  padding-left: 1.25rem;
  padding-right: 1.75rem;
  font-size: $font-size-base;

  svg {
    position: relative;
    display: inline-block;
    top: 2px;
    width: 22px;
    height: 22px;
    margin: 0 8px 0 0;
    fill: currentColor;
    vertical-align: top;
  }

  @include media-breakpoint-down(xs) {
    padding-left: $btn-padding-x-sm;
    padding-right: $btn-padding-x-sm;

    svg {
      top: 3px;
      width: 16px;
      height: 16px;
      margin: 0 5px 0 0;
    }
  }
}


// Button Sizes
.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, 1.375rem, $btn-line-height-lg, $btn-border-radius-lg);

  @include media-breakpoint-down(xs) {
    @include button-size($btn-padding-y, $btn-padding-x, 1.125rem, $btn-line-height, $btn-border-radius);
  }
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, 1rem, $btn-line-height-sm, $btn-border-radius-sm);
  border-width: 3px;
}


// Block button
.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
