// Headings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 {
  font-size: $h1-font-size;
  line-height: ($font-size-base * 5.75);
  text-transform: uppercase;
}

h2, .h2 {
  font-size: $h2-font-size;
  line-height: ($font-size-base * 4.5);
  text-transform: uppercase;
}

h3, .h3 {
  font-size: $h3-font-size;
  line-height: ($font-size-base * 2.875);
  text-transform: uppercase;
}

h4, .h4 {
  font-size: $h4-font-size;
  line-height: ($font-size-base * 2.875);
  text-transform: uppercase;
}

h5, .h5 {
  font-size: $h5-font-size;
  line-height: ($font-size-base * 1.875);
  text-transform: uppercase;
}

h6, .h6 {
  font-size: $h6-font-size;
  line-height: $line-height-base;
  text-transform: uppercase;
}


.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
}


// Horizontal rules
hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}


// Emphasis
small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}


// Lists
.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


// Blockquotes
.blockquote {
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 1.875rem 0;
  font-size: $blockquote-font-size;
  font-family: $font-family-secondary;
  line-height: 1.25em;
  text-align: center;
  text-transform: uppercase;
  color: $primary;
  border-top: .375rem solid $primary;
  border-bottom: .375rem solid $primary;
}

.blockquote-footer {
  display: block;
  font-size: 80%; // back to default font-size
  color: $blockquote-small-color;

  &::before {
    content: "\2014 \00A0"; // em dash, nbsp
  }
}

@include media-breakpoint-down(xs) {
  h1, .h1 {
    font-size: 3.75rem;
    line-height: 1.2;
  }

  h2, .h2 {
    font-size: $h3-font-size;
    line-height: 1.2;
  }

  h3, .h3 {
    font-size: $h4-font-size;
    line-height: 1.2;
  }

  h4, .h4 {
    font-size: $h5-font-size;
    line-height: 1.2;
  }

  h5, .h5 {
    font-size: $h6-font-size;
    line-height: 1.2;
  }

  h6, .h6 {
    font-size: .875rem;
    line-height: 1.2;
  }
}
