.location-connected-section {
  @include grid-gutter(.625rem);
  margin-bottom: 6.25rem;

  .container {
    &:first-child {
      padding-left: 5.625rem;
      padding-right: 5.625rem;
    }

    &:last-child {
      position: relative;
      right: -4.375rem;
    }
  }

  .row-1 {
    align-items: flex-end;

    .col {
      &:last-child {
        max-width: 26.875rem;
      }
    }
  }

  .row-2 {
    justify-content: flex-end;

    [class*="col-"] {
      display: flex;

      figure {
        width: 100%;
      }
    }
  }

  // Images
  figure {
    display: block;
    margin-bottom: .625rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      opacity: 0;
    }
  }

  .image-6 {
    margin: 0;
  }

  // Text
  h2 {
    max-width: 21.25rem;
    margin: 4.5rem 0 2.5rem auto;
    color: $black;
  }

  p {
    // max-width: 28.75rem;
    margin-left: auto;
    font-weight: $font-weight-light;

    small {
      font-weight: inherit;
    }
  }

  .blockquote {
    position: relative;
    margin: 4rem 0 1.5rem;
    max-width: 36.25rem;
    text-align: left;
    font-size: 2.5rem;
    line-height: 1.15em;
    border-color: transparent;
    z-index: 1;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 12.5rem;
      height: .375rem;
      background-color: $primary;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    img {
      position: absolute;
      top: 8.5rem;
      right: -12.5rem;
      width: 16.25rem;
      height: auto;
      z-index: -1;
    }
  }

  .text-2 {
    padding-right: 3.75rem;

    h2 {
      text-align: right;
    }
  }

  .map-legend {
    @include grid-gutter(1.875rem);
    margin-bottom: 1px;
    padding: 1.25rem 2.1875rem;
    background-color: $white;

    h4 {
      margin: 0 0 1rem;
      font-size: 1.125rem;
      font-weight: $font-weight-normal;
      letter-spacing: .022222222em;
    }

    h5 {
      margin: .25rem 0 .25rem;
      padding-bottom: .25rem;
      font-size: .875rem;
      font-weight: $font-weight-normal;
      letter-spacing: .057142857em;
      border-bottom: 1px solid $primary;
    }

    p {
      font-size: .75rem;
      font-weight: $font-weight-light;
    }

    img {
      width: auto;
      height: 1.25rem;
      margin-right: .25rem;
    }

    .key-item {
      position: relative;
      margin-bottom: .6rem;
      padding-left: 2.25rem;
      font-size: .75rem;
      font-weight: $font-weight-light;
      line-height: .875rem;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.875rem;
        height: .875rem;
        vertical-align: top;
      }

      &.line {
        span {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 40%;
            transform: translateY(-50%);
            background-color: $white;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .container {
      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .container {
      &:last-child {
        right: auto;

        & > .row {
          margin-right: -1.25rem;
        }
      }
    }

    .text-2 {
      padding-right: 1.875rem;
    }
  }

  @include media-breakpoint-down(md) {
    .container {
      &:last-child {
        right: auto;

        & > .row {
          margin-right: -.3125rem;
        }
      }
    }

    .row-1 {
      .col {
        &:last-child {
          max-width: 20rem;
        }
      }
    }

    .text-2 {
      padding-right: 0;

      h2 {
        margin-left: 0;
        text-align: left;
      }
    }

    .blockquote {
      img {
        right: -8.5rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .row-1 {
      .col {
        &:last-child {
          max-width: 15rem;
        }
      }
    }

    .image-6 {
      margin-top: 2.5rem;
    }

    h2 {
      margin-top: 2.5rem;
    }

    .blockquote {
      max-width: 100%;
      font-size: 1.5rem;
      line-height: 1.25em;

      img {
        display: none
      }
    }
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 5rem;

    .row-1 {
      .col {
        &:last-child {
          flex: 0 0 auto;
          max-width: 100%;
          width: 100%;
        }
      }
    }

    h2 {
      margin-bottom: 1rem;
    }

    .map-legend {
      padding: 1.75rem .9375rem;
    }
  }
}
