.page-header {
  margin-bottom: 3.75rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  img {
    display: none;
  }

  .content {
    display: flex;
    align-items: flex-end;
    position: relative;
    min-height: 33.125rem;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 16.875rem;
      background-image: linear-gradient(-180deg, rgba($black, 0) 0%, rgba($black, .25) 100%);
    }
  }

  h1 {
    position: relative;
    margin: 0 0 -.375rem 13.4375rem;
    color: $white;
  }
}

.top-text {
  max-width: (38.125rem + 26.875rem);
  margin: 0 0 9.375rem;
  padding-left: 26.875rem;
  line-height: 1.4em;
  font-size: $font-size-lg;
}

@include media-breakpoint-down(xl) {
  .page-header {
    h1 {
      margin-left: 6.71875rem;
    }
  }

  .top-text {
    max-width: (38.125rem + 13.4375rem);
    padding-left: 13.4375rem;
  }
}

@include media-breakpoint-down(lg) {
  .page-header {
    margin-top: 4.6875rem;
    margin-left: -.9375rem;
    margin-right: -.9375rem;
  }
}

@include media-breakpoint-down(md) {
  .page-header {
    h1 {
      margin-left: 3.359375rem;
    }
  }

  .top-text {
    max-width: (38.125rem + 6.71875rem);
    padding-left: 6.71875rem;
  }
}

@include media-breakpoint-down(sm) {
  .page-header {
    .content {
      position: relative;
      min-height: 0;
      padding-top: percentage(3 / 4);
    }

    h1 {
      position: absolute;
      left: 0;
      bottom: -.1875rem;
      width: 100%;
      margin: 0;
      padding: 0 30px;
      font-size: $h2-font-size;
      line-height: 1em;
    }
  }

  .top-text {
    max-width: 100%;
    padding-left: 0;
    margin-bottom: 5rem;
  }
}

@include media-breakpoint-down(xs) {
  .page-header {
    h1 {
      bottom: -.1875rem;
      padding: 0 .9375rem;
      font-size: 3rem;
      line-height: 1em;
    }
  }
}
