.page-location {
  .page-header {
    h1 {
      max-width: 30rem;
    }
  }

  @include media-breakpoint-down(sm) {
    .page-header {
      h1 {
        max-width: 25rem;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .page-header {
      h1 {
        max-width: 15rem;
      }
    }
  }
}
