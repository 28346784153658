.before-footer-section {
  @include grid-gutter(3.75rem);
  margin-bottom: 1.25rem;

  // Images
  figure {
    display: block;
    margin-bottom: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      opacity: 0;
    }
  }

  // Text
  h2 {
    position: relative;
    max-width: 20rem;
    margin: 0 0 2.5rem;
    letter-spacing: .075em;
    color: $black;
    z-index: 1;

    span {
      display: block;
      white-space: nowrap;
    }
  }

  h4 {
    margin: 0 0 1.75rem;
    font-size: $h5-font-size;
    font-weight: $font-weight-light;
    text-transform: none;
    letter-spacing: .05em;
  }

  .btn {
    width: 17.5rem;
    max-width: 100%;
  }

  .text-1 {
    padding-left: 4.375rem;
  }

  @include media-breakpoint-down(xl) {
    .text-1 {
      padding-left: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 6.25rem;

    h2 {
      letter-spacing: .025em;
    }

    h4 {
      letter-spacing: .025em;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 5rem;

    [class*="col-"] {
      &:first-child {
        order: 2;
      }
    }

    h2 {
      max-width: 100%;
    }

    h4 {
      margin-top: 2.5rem;
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      margin-bottom: 1rem;
    }

    h4 {
      margin-bottom: .5rem;
      font-size: 1.25rem;
    }

    .btn {
      width: auto;
    }
  }
}
