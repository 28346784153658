.accommodation-select-level-section {
  @include grid-gutter(0);
  margin-bottom: 6.25rem;
  text-align: center;

  .row {
    margin-right: -4.375rem;

    [class*="col-"] {
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-right: 4.375rem;
      }
    }
  }

  h2 {
    margin: 0 0 3.5rem;
    font-size: 1.375rem;
    letter-spacing: .022727273em;
    color: $black;
  }

  h4 {
    margin: 1rem 0 0;
    font-size: .75rem;
    letter-spacing: .058333333em;
  }

  .floor-image {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: percentage(1060 / 1120) 0 0;

    img {
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;

      &[class*="floor"] {
        mix-blend-mode: multiply;
      }

      &.active {
        display: block;
      }
    }
  }

  .floor-table {
    width: 100%;
    font-size: .875rem;
    text-transform: uppercase;
    letter-spacing: .128571429em;
    // color: $grey-5;

    tr {
      &:nth-child(even) {
        background-color: $grey-2;
      }
    }

    th {
      padding: .5rem 1rem;
      font-family: $font-family-secondary;
      font-weight: $font-weight-normal;
      text-align: right;
      letter-spacing: .014285714em;
      border-bottom: 1px solid $grey-2;
      color: $body-color;

      &:first-child {
        padding-left: 2rem;
        text-align: left;
      }
    }

    td {
      padding: 1.5625rem 1rem;
      text-align: right;

      &:first-child {
        padding-left: 2rem;
        font-weight: $font-weight-medium;
        font-family: $font-family-secondary;
        font-size: 1.25rem;
        letter-spacing: normal;
        text-align: left;
      }

      &:last-child {
        padding-right: 2rem;
        font-weight: $font-weight-bold;
        letter-spacing: .064285714em;
      }
    }

    .available {
      transition: $transition-base;

      td {
        position: relative;

        &:last-child {
          color: $body-color;

          span {
            display: block;
          }

          .btn {
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            display: none;
          }
        }
      }

      &:hover {
        color: $body-color;
        background-color: $primary;

        td {
          &:last-child {
            span {
              display: none;
            }

            .btn {
              display: block;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .floor-table {
      letter-spacing: normal;

      th {
        padding: .5rem .625rem;
        letter-spacing: normal;

        &:first-child {
          padding-left: 1.25rem;
        }
      }

      td {
        padding: 1.5625rem .625rem;

        &:first-child {
          padding-left: 1.25rem;
        }

        &:last-child {
          padding-right: 1.25rem;
          letter-spacing: normal;
        }
      }

      .available {
        td {
          &:last-child {
            .btn {
              right: 1.25rem;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .row {
      align-items: center;
      margin-right: 0;

      [class*="col-"] {
        &:first-child {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .row {
      margin-left: -.9375rem;
      margin-right: -.9375rem;
    }

    .floor-table {
      color: $body-color;

      &,
      tbody,
      tr,
      td {
        display: block;
      }

      thead {
        display: none;
      }

      tr {
        padding: .9375rem;
      }

      td {
        padding: 0;
        text-align: left;

        &:empty {
          display: none;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &:nth-child(2) {
          &:before {
            content: 'NIA: ';
          }
        }

        &:nth-child(3) {
          &:before {
            content: 'Terrace: ';
          }
        }
      }

      .available {
        position: relative;

        td {
          position: static;

          &:last-child {
            span {
              display: block;
            }

            .btn {
              right: .9375rem;
              display: inline-block;
            }
          }
        }

        &:hover {
          td {
            &:last-child {
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
