.page-contact {
  @include media-breakpoint-down(lg) {
    .page-content {
      padding-bottom: 4.375rem;
    }
  }

  @include media-breakpoint-down(sm) {
    .page-content {
      padding-bottom: 0;
    }
  }
}

.contact-section {
  @include grid-gutter(5rem);
  max-width: 100%;
  width: 54.375rem;
  margin: 0 auto;

  h2 {
    margin: 0 0 4.75rem;
    font-size: 1.375rem;
    letter-spacing: .022727273em;
    text-align: center;
    color: $black;
  }

  @include media-breakpoint-down(sm) {
    h2 {
      margin-bottom: 2.5rem;
      text-align: left;
    }
  }
}


.contact-item {
  h3 {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    overflow: hidden;
    opacity: 0;
  }

  .logo {
    display: block;
    width: auto;
    height: 2.875rem;
    margin: 0 auto 4.25rem;
  }

  .tel {
    display: inline-block;
    margin-bottom: 2rem;
    font-weight: $font-weight-bold;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  .agent-info {
    margin: 0 0 2.75rem;

    &:last-child {
      margin-bottom: 0;
    }

    h5 {
      margin: 0 0 .5rem;
      font-family: $font-family-base;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      text-transform: none;
    }

    ul {
      @include list-unstyled;
      margin: 0;
      font-weight: $font-weight-light;

      a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 5rem;

    .logo {
      margin: 0 0 2.5rem;
    }
  }
}
