.header {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: $max-width;
  transform: translate3d(-50%, 0, 0);
  box-shadow: -2px 0 0 0 $primary;
  z-index: $z-index-header;
  pointer-events: none;
  transition: width .75s ease, max-width .75s ease, transform .75s ease;
  will-change: width, max-width;

  .full-width & {
    max-width: 100%;
  }

  & > * {
    pointer-events: auto;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11.25rem;
    height: 11.25rem;
    padding: 2.25rem;
    background-color: $primary;

    &:focus {
      outline: 0;
    }

    .page-loading & {
      pointer-events: none;
    }

    svg {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  .menu-toggle {
    position: absolute;
    display: block;
    top: 4.375rem;
    right: 4.375rem;
    width: 4.5rem;
    height: 4.5rem;
    margin: 0;
    padding: 0;
    background-color: $grey-2;
    border: 0;
    cursor: pointer;
    transition: $transition-base, right 0s;
    transform: translate(50%, -50%);

    .has-scrollbar & {
      right: (4.375rem + .9375);
    }

    &:focus {
      outline: 0;
      // box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
    }

    .page-loading & {
      pointer-events: none;
    }

    .icon {
      &,
      & > span {
        position: absolute;
        left: 0;

        &:before,
        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 2.25rem;
          height: 2px;
          background-color: $secondary;
          transition: top .3s ease, opacity .3s ease, transform .3s ease;
        }
      }

      & {
        top: 50%;
        left: 1.125rem;
        transition: opacity .3s ease;

        &:before { top: -4px; }
        &:after  { top: 2px; }

        .page-loading & {
          opacity: 0;
        }
      }

      span {
        top: 0;

        &:before { top: -10px; }
        &:after  { top: 8px; }
      }

      // Menu Open
      .menu-open & {
        &,
        & > span {
          &:before,
          &:after {
            transition: top .3s ease, opacity .3s ease, transform .3s ease;
          }
        }

        & {
          &:before {
            top: 0;
            transform: rotate(45deg);
          }

          &:after {
            top: 0;
            transform: rotate(-45deg);
          }
        }

        span {
          &:before,
          &:after {
            opacity: 0;
            top: 0;
          }
        }
      }
    }

    &:hover {
      .icon {
        &,
        & > span {
          &:before,
          &:after {
            transition: top .3s ease, opacity .3s ease, transform .3s ease;
          }
        }

        & {
          &:before {
            top: 0;
            transform: none;
          }

          &:after {
            top: 0;
            transform: none;
          }
        }

        span {
          &:before,
          &:after {
            top: 0;
          }
        }
      }
    }
  }

  .menu-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.125rem;
    margin-left: -1.125rem;
    border: 3px solid rgba($black, .1);
    border-left-color: $black;
    transform: translateZ(0);
    animation: menu_loader 1s infinite linear;
    animation-play-state: paused;
    opacity: 0;
    transition: opacity .3s ease;
    pointer-events: none;
    z-index: 1000;

    &,
    &:after {
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
    }

    .page-loading & {
      animation-play-state: running;
      opacity: 1;
    }
  }

  @include media-breakpoint-up(xl) {
    .header-small & {
      .logo {
        width: 6.875rem;
        height: 6.875rem;
        padding: .9375rem;
      }

      .menu-toggle {
        top: 3.4375rem;
      }
    }

    .home.header-small &,
    .menu-open.header-small & {
      .logo {
        width: 11.25rem;
        height: 11.25rem;
        padding: 2.25rem;
      }

      .menu-toggle {
        top: 4.375rem;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: .9375rem;
    background-color: $primary;
    box-shadow: -2px 0 0 0 $primary, 2px 0 0 0 $primary;

    .logo {
      width: 5.625rem;
      height: 5.625rem;
      padding: .9375rem;
      background-color: $primary;

      svg {
        fill: $white;
      }
    }

    .menu-toggle {
      position: relative;
      top: auto;
      right: auto;
      width: 3.75rem;
      height: 3.75rem;
      transform: none;

      .has-scrollbar & {
        right: .9375rem;
      }

      .icon {
        left: .75rem;
      }
    }
  }
}


@keyframes menu_loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
