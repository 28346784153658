@mixin grid-gutter($width: $spacer) {
  .row {
    margin-left: -($width / 2);
    margin-right: -($width / 2);

    & > .col,
    & > [class^="col-"],
    & > [class*=" col-"] {
      padding-left: ($width / 2);
      padding-right: ($width / 2);
    }
  }
}


@mixin button-variant($color) {
  $hover-color: darken($color, 7.5%);
  $active-color: darken($color, 10%);

  color: color-yiq($color);
  background-color: $color;

  @include hover {
    color: color-yiq($hover-color);
    background-color: $hover-color;
  }

  &:focus,
  &.focus {
    // box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($color);
    background-color: $color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: color-yiq($active-color);
    background-color: $active-color;

    &:focus {
      // box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
  }
}


@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    // box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      // box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
  }
}
