.animate,
.animate-in-view,
.animate-multiple [data-animation] {
  visibility: hidden;
  animation-duration: 1s;
  will-change: transform, opacity;

  &.animated {
    visibility: visible;
    animation-fill-mode: both;
    will-change: unset;
  }
}


// Animations
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}


@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@include media-breakpoint-down(sm) {
  .fadeInLeft {
    animation-name: fadeInUp;
  }

  .fadeInRight {
    animation-name: fadeInUp;
  }
}
