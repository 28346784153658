.location-life-style-section {
  @include grid-gutter(.625rem);
  margin-bottom: 6.25rem;

  .row-1 {
    .col {
      &:first-child {
        max-width: 18.5rem;
      }

      &:last-child {
        max-width: 24.375rem;
      }
    }
  }

  .row-2 {
    [class*="col-"] {
      display: flex;

      figure {
        width: 100%;
      }
    }
  }

  .row-3 {
    margin-right: -4.6875rem;
    padding-left: 2.1875rem;

    .col {
      &:first-child {
        flex-grow: 2;
        max-width: 24.375rem;
      }

      &:last-child {
        flex-grow: 2;
        max-width: 40rem;
      }
    }
  }

  .row-4 {
    [class*="col-"] {
      display: flex;

      figure {
        width: 100%;
      }
    }
  }

  // Images
  figure {
    display: block;
    margin-bottom: .625rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      opacity: 0;
    }
  }

  .image-1 {
    margin-right: 2.1875rem;
  }

  .image-5,
  .image-6 {
    margin-top: 8.4375rem;
  }

  // Text
  h2 {
    position: relative;
    max-width: 12.5rem;
    margin: 1.25rem 0 2.5rem;
    color: $black;

    img {
      position: absolute;
      top: .5rem;
      left: 11.25rem;
      width: 6.875rem;
      height: auto;
    }
  }

  p {
    max-width: 26.25rem;
    font-weight: $font-weight-light;
  }

  ol {
    list-style-position: inside;
    margin: 0;
    padding: 0;
    font-size: .75rem;

    li {
      margin-bottom: 1.125rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .blockquote {
    position: relative;
    left: 9.25rem;
    margin: 4rem 0 2.75rem;
    max-width: 32.5rem;
    text-align: left;
    border-color: transparent;
    z-index: 1;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 12.5rem;
      height: .375rem;
      background-color: $primary;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    img {
      position: absolute;
      top: 0;
      left: -17rem;
      width: 17.1875rem;
      height: auto;
    }
  }

  .text-1 {
    margin-left: 1.5625rem;
  }

  .text-2 {
    margin-top: 4.5rem;
  }

  @include media-breakpoint-down(xl) {
    .row-1 {
      .col {
        &:first-child {
          max-width: 17rem;
        }

        &:last-child {
          max-width: 20rem;
        }
      }
    }

    .row-3 {
      .col {
        &:first-child {
          max-width: 20rem;
        }

        &:last-child {
          max-width: 35rem;
        }
      }
    }

    .image-1 {
      min-height: 27rem;
    }

    .image-2,
    .image-3 {
      min-height: 21.5rem;
    }

    .image-5,
    .image-6 {
      margin-top: 5rem;
    }

    .text-2 {
      margin-top: 2.5rem;
    }

    .blockquote {
      left: 2.75rem;
      margin: 2.75rem 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .row-3 {
      margin-right: -1.25rem;
    }
    .text-1 {
      margin-left: -5px;
    }
  }

  @include media-breakpoint-down(md) {
    .row-1 {
      .col {
        &:last-child {
          max-width: none;
        }

        &:nth-child(2) {
          order: 3;
          width: 100%;
          flex: 0 0 auto;
        }
      }
    }

    .row-3 {
      padding-left: 0;
    }

    .image-2,
    .image-3 {
      min-height: 0;
    }

    .image-4 {
      max-height: 22rem;
    }

    .text-3 {
      display: flex;
      justify-content: flex-end;
    }

    .blockquote {
      left: 0;
      margin: 2.75rem 0;
      max-width: 27.5rem;
    }
  }

  @include media-breakpoint-down(sm) {
    .row-1 {
      align-items: center;
    }

    .row-3 {
      align-items: flex-end;
      margin-right: -.3125rem;

      .col {
        &:first-child {
          max-width: 50%;
        }

        &:last-child {
          flex: 0 0 auto;
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .image-1 {
      margin-right: 0;
      min-height: 0;
    }

    .image-4 {
      min-height: 0;
    }

    .image-5,
    .image-6 {
      margin-top: 0;
    }

    .text-2 {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    h2 {
      max-width: 100%;
      margin-top: 2.5rem;

      img {
        display: none;
      }
    }

    p {
      max-width: 100%;
    }

    .blockquote {
      left: 0;
      margin: 2.75rem 0;
      max-width: 100%;

      img {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 5rem;

    .row-1,
    .row-3 {
      .col {
        &:first-child {
          flex: 0 0 auto;
          max-width: 100%;
          width: 100%;
        }
      }
    }

    h2 {
      margin-bottom: 1rem;
    }
  }
}
