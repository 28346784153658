@import "bootstrap/scss/functions";
@import "functions";

@import "bootstrap/scss/variables";
@import "variables";

@import "bootstrap/scss/mixins";
@import "mixins";


// BOOTSTRAP
// @import "bootstrap/scss/print";
@import "bootstrap/scss/reboot";
// @import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";
// @import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group";
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
@import "bootstrap/scss/utilities";


// VENDOR
@import 'vendor/flickity';
@import 'vendor/full-page';
@import 'vendor/jquery.fancybox';


// PARTIALS
@import "partials/scaffolding";
@import "partials/animations";
@import "partials/typography";
@import "partials/buttons";
@import "partials/header";
@import "partials/footer";
@import "partials/main-menu";
@import "partials/page-header";
@import "partials/home-slider";
@import "partials/building-reception-section";
@import "partials/building-terrace-section";
@import "partials/building-office-section";
@import "partials/building-specification-section";
@import "partials/location-life-style-section";
@import "partials/location-location-section";
@import "partials/location-connected-section";
@import "partials/accommodation-space-plans-section";
@import "partials/accommodation-select-level-section";
@import "partials/accommodation-floor-plan-section";
@import "partials/accommodation-space-modal";
@import "partials/before-footer-section";


// PAGES
@import "pages/home";
@import "pages/location";
@import "pages/building";
@import "pages/accommodation";
@import "pages/downloads";
@import "pages/contact";



// body:after {
//   content: 'no mq';
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   padding: 5px;
//   background: gold;
//   line-height: 1;
//   font-size: 12px;
//   font-family: monospace;
//   font-weight: bold;
//   text-transform: uppercase;
//   z-index: 9999999999;

//   @include media-breakpoint-only(xxl) { & { content: 'xxl'; } }
//   @include media-breakpoint-only(xl)  { & { content: 'xl'; } }
//   @include media-breakpoint-only(lg)  { & { content: 'lg'; } }
//   @include media-breakpoint-only(md)  { & { content: 'md'; } }
//   @include media-breakpoint-only(sm)  { & { content: 'sm'; } }
//   @include media-breakpoint-only(xs)  { & { content: 'xs'; } }
// }
