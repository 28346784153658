.page-downloads {}

.downloads-section {
  margin-bottom: 10.75rem;
  @include grid-gutter(.625rem);

  [class*="col-"] {
    display: flex;
    align-items: stretch;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 7.25rem;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 5rem;
  }
}


.download-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: .625rem;
  padding: 2.5rem 1.25rem;
  text-align: center;
  background-color: $white;

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
    color: $secondary;
    background-color: $primary;
  }

  .icon {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid rgba($black, .25);
    border-radius: 50%;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1rem;
      height: 1.125rem;
      transform: translate(-50%, -50%);
      fill: currentColor;
    }
  }

  h3 {
    margin: 1rem 0;
    font-size: $h4-font-size;
  }

  .size {
    display: block;
    margin-top: auto;
    font-size: 1.125rem;
    font-family: $font-family-secondary;
    font-weight: $font-weight-light;
    letter-spacing: .055555556em;
    color: $body-color;
  }
}


.downloads-images-section {
  margin-bottom: 6.25rem;
  @include grid-gutter(.625rem);

  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 3.125rem;
    padding: 0 4.375rem;

    h2 {
      margin: 0;
      font-size: $h3-font-size;
      letter-spacing: .05em;
    }

    h5 {
      margin: 0;
      font-size: 1.125rem;
      font-weight: $font-weight-normal;
      letter-spacing: .022222222em;
    }

    .btn {
      width: 17.5rem;
      max-width: 100%;
      margin-top: .625rem;
      color: $body-color;
    }
  }

  .image-item {
    display: block;
    margin: 0 0 .625rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    a {
      display: block;
      padding-top: 71.7703349%;
    }

    img {
      display: none;
    }
  }

  @include media-breakpoint-down(lg) {
    header {
      padding: 0 2.5rem;
    }
  }

  @include media-breakpoint-down(md) {
    header {
      padding: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    header {
      display: block;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 5rem;

    header {
      margin-bottom: 2.5rem;

      h5 {
        margin: 0 0 1.5rem;
      }

      .btn {
        width: auto;
      }
    }
  }
}
