$img-path: '../img/';
$fonts-path: '../fonts/';


// Colors
$black: #000000;
$white: #ffffff;

$grey-1: #f3f3f4;
$grey-2: #e9e9e9;
$grey-3: #1f1f1f;
$grey-4: #1c1c1c;
$grey-5: #c2c2c2;

$primary: #e53a4e;
$secondary: #2d2224;

$body-bg: $grey-1;
$body-color: $secondary;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'white': $white,
  'black': $black
);


// Layout
$spacer: 1rem;
$paragraph-margin-bottom: $spacer;

$max-width: 1440px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: ($max-width + 20px)
);

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// );

$z-index-menu: 40;
$z-index-header: 50;

$transition-base: all .3s ease;


// Typography
$font-family-sans-serif: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-secondary: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * .875);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: ($font-size-base * 5.625);
$h2-font-size: ($font-size-base * 4.375);
$h3-font-size: ($font-size-base * 2.5);
$h4-font-size: ($font-size-base * 2.25);
$h5-font-size: ($font-size-base * 1.5);
$h6-font-size: $font-size-base;

$headings-margin-bottom: $spacer;
$headings-font-family: $font-family-secondary;
$headings-font-weight: $font-weight-medium;
$headings-line-height: 1.2;
$headings-color: inherit;

$lead-font-size: ($font-size-base * 1.25);
$lead-font-weight: $font-weight-normal;

$small-font-size: 80%;

// $text-muted: $gray-600;

$blockquote-small-color: inherit;
$blockquote-font-size: ($font-size-base * 1.5);

// $hr-border-color:             rgba($black, .1) !default;
// $hr-border-width:             $border-width !default;

// $mark-padding:                .2em !default;

// $dt-font-weight:              $font-weight-bold !default;

// $kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
// $nested-kbd-font-weight:      $font-weight-bold !default;

// $list-inline-padding:         .5rem !default;

// $mark-bg:                     #fcf8e3 !default;

// $hr-margin-y:                 $spacer !default;


// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: $primary;
$link-hover-decoration: underline;


// Buttons + Forms
$input-btn-padding-y: .8125rem;
$input-btn-padding-x: ($input-btn-padding-y * 2);
$input-btn-line-height: 1.625rem;

$input-btn-focus-width: .2rem;
$input-btn-focus-color: rgba($component-active-bg, .25);
$input-btn-focus-box-shadow: none;

$input-btn-padding-y-sm: .4375rem;
$input-btn-padding-x-sm: ($input-btn-padding-y-sm * 2);
$input-btn-line-height-sm: 1.25rem;

$input-btn-padding-y-lg: 1.3125rem;
$input-btn-padding-x-lg: ($input-btn-padding-y-lg * 2);
$input-btn-line-height-lg: 1.875rem;

$input-btn-border-width: .25rem;


// Buttons
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-medium;
$btn-box-shadow: none;
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: .5;
$btn-active-box-shadow: none;

$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: .625rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
