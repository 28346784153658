.home-slider-wrapper {
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 11.25rem;
    height: 100%;
    background-image: linear-gradient(-90deg, rgba($black, 0) 0%, rgba($black, .6) 100%);
    opacity: .4;
    z-index: 10;
  }
}

.home-slider {
  .home-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(-195deg, rgba($black, 0) 35%, rgba($black, .6) 90%);
    }

    .fp-tableCell {
      height: auto !important;
    }

    img {
      display: none;
    }

    .content {
      position: relative;
      margin: 0 0 10% 25%;
      padding-right: .9375rem;
      color: $white;
      z-index: 10;

      h1 {
        max-width: 40.5rem;
        margin: 0 0 3.125rem;
        font-size: ($font-size-base * 6.25);
        line-height: 1em;
        letter-spacing: .05em;
      }

      p {
        max-width: 36.25rem;
        margin: 0 0 2.5rem;
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        line-height: 1.6em;
      }

      [class*="btn-outline"] {
        min-width: 13.125rem;
        margin-right: 4rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .btn-link {
        color: inherit;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .home-slide {
      .content {
        margin: 0 0 5% 25%;

        h1 {
          max-width: 30rem;
          margin: 0 0 2.5rem;
          font-size: 3.75rem;
        }

        p {
          max-width: 30rem;
          font-size: $font-size-base;
        }

        [class*="btn-outline"] {
          margin-right: 2.5rem;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .home-slide {
      justify-content: center;

      .content {
        margin: 0;
        padding-top: 8.125rem;
        padding-left: 11.25rem;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .home-slide {
      .content {
        padding-top: 6.875rem;
        padding-left: 3.125rem;
      }
    }
  }
}

.home-slider-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0;
  list-style: none;
  pointer-events: none;
  z-index: 15;
  opacity: 0;
  transform: translateX(-100%);
  transition: all .5s ease;

  li {
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    height: auto;
    margin: 0 0 .75rem;
    padding: 0;
    font-family: $font-family-secondary;
    font-size: 1.125rem;
    line-height: 2rem;
    text-align: left;
    color: $white;
    opacity: .6;
    font-feature-settings: 'tnum' 1;
    pointer-events: auto;
    transition: $transition-base;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      width: 5rem;
      height: 1px;
      margin-right: 1rem;
      background-color: $grey-2;
    }

    span {
      transform: scale(0.75);
      transition: all 0.3s ease;
    }

    &.active {
      opacity: 1;
      text-shadow: 1px 1px 2px rgba($black, .25);

      span {
        transform: scale(1);
      }
    }
  }

  @include media-breakpoint-down(xs) {
    width: 3.75rem;

    li {
      &:before {
        width: .3125rem;
        margin-right: .625rem;
      }
    }
  }
}
