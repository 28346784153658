.building-office-section {
  @include grid-gutter(.625rem);
  margin-bottom: 6.25rem;

  .container {
    padding-left: 16.5625rem;
    padding-right: 4.375rem;
  }

  .row {
    &:last-child {
      [class*="col-"] {
        display: flex;

        figure {
          width: 100%;
        }
      }
    }
  }

  // Images
  figure {
    display: block;
    margin-bottom: .625rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      opacity: 0;
    }
  }

  .image-1 {
    margin-left: -1rem;
    margin-right: -8.75rem;
  }

  // Text
  h2 {
    position: relative;
    margin: 3.25rem 0 2.5rem;
    color: $black;
    z-index: 1;

    span {
      display: block;
      white-space: nowrap;
    }
  }

  .btn {
    width: 17.5rem;
    max-width: 100%;
  }

  @include media-breakpoint-down(xl) {
    .container {
      padding-left: 4.375rem;
    }

    .image-1 {
      margin-left: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }

    .image-1 {
      min-height: 21.5rem;
      margin-right: -.9375rem;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 5rem;

    .row {
      &:first-child {
        [class*="col-"] {
          &:first-child {
            order: 2;
          }
        }
      }
    }

    .image-1 {
      min-height: 0;
      margin-right: 0;
    }

    .text-1 {
      margin-bottom: 3.5rem;
    }

    h2 {
      margin-top: 2.5rem;
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      margin-bottom: 1.5rem;
    }

    .btn {
      width: auto;
    }
  }
}
