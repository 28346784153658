.page-accommodation {
  .page-header {
    margin-bottom: 9.375rem;

    h1 {
      max-width: 40rem;
      color: $black;
    }
  }

  // Grey text for table
  .grey {
    color: grey;
  }

  // Remove padding for logos
  .td-remove-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .available-highlighted {
    background: $primary;
  }

  @include media-breakpoint-down(sm) {
    .page-header {
      margin-bottom: 5rem;
    }
  }

  @include media-breakpoint-down(xs) {
    .page-header {
      h1 {
        max-width: 25rem;
      }
    }
  }
}
