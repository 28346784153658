body {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.fancybox-active {
    overflow-y: hidden !important;
  }

  &.menu-open {
    overflow-y: hidden !important;

    &.has-scrollbar {
      padding-right: 15px;
    }
  }
}

#main-wrapper {
  position: relative;
}

.ajax-page-container {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  transform: translate3d(0, 0, 0);
  opacity: 0;
  background-color: $body-bg;
  will-change: transform;
  overflow: hidden;
}

.page-wrapper {
  position: relative;
  width: 90rem;
  max-width: 100%;
  margin: 0 auto;
  padding: 4.375rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 25rem;
    background-color: $white;
    transform: translateX(-50%);
    z-index: -1;
  }
}

.container {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: $black;
  opacity: 0;
  pointer-events: none;
  transition: all .5s ease;
  z-index: ($z-index-header + 5);
}

@include media-breakpoint-down(lg) {
  .page-wrapper {
    padding: 0;
  }

  .page-content {
    padding: .9375rem .9375rem 0;
  }

  .loading-bar {
    background-color: $primary;
  }
}

@include media-breakpoint-down(sm) {
  .page-wrapper {
    &:before {
      display: none;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  transition: $transition-base;
}

a,
button {
  &:focus {
    outline: 0;
  }
}
