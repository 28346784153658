.building-terrace-section {
  @include grid-gutter(.625rem);
  margin-bottom: 6.25rem;

  .row {
    padding-right: 4.6875rem;
  }

  // Images
  figure {
    display: block;
    margin-bottom: .625rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      opacity: 0;
    }
  }

  .image-1 {
    position: relative;
    right: -4.375rem;
    margin-left: 2.1875rem;
  }

  // Text
  h2 {
    max-width: 28.75rem;
    margin: 3.5rem 0 3.25rem;
    color: $black;
  }

  p {
    max-width: 28.75rem;
    margin: 0;
    font-weight: $font-weight-light;
  }

  .text-1 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @include media-breakpoint-down(xl) {
    .row {
      padding-right: .3125rem;
    }

    .image-1 {
      right: -4.375rem;
      margin-left: 0;
    }

    h2 {
      margin-top: 2.75rem;
    }

    .text-1 {
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .image-1 {
      right: 0;
      margin-left: 4.375rem;
      margin-right: -.9375rem;
    }

    h2 {
      margin: 2.5rem 0;
    }

    .text-1 {
      padding-left: 1.875rem;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 5rem;

    .row {
      padding-right: 0;
    }

    .image-1 {
      right: 0;
      margin-left: 0;
      margin-right: 0;
    }

    h2 {
      margin: 2.5rem 0;
    }

    .text-1 {
      padding-left: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      margin-bottom: 1rem;
    }
  }
}
