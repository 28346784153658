.accommodation-space-plans-section {
  @include grid-gutter(5rem);

  .container {
    padding-left: 5.3125rem;
    padding-right: 5.3125rem;
  }

  .row {
    [class*="col-"] {
      &:last-child {
        margin-top: 15rem;
      }
    }
  }

  h2 {
    margin: 0 0 5rem;
    color: $black;
  }

  @include media-breakpoint-down(lg) {
    @include grid-gutter(2.5rem);
  }

  @include media-breakpoint-down(md) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .row {
      [class*="col-"] {
        &:last-child {
          margin-top: 0;
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}


.space-plans-item {
  width: 100%;
  max-width: 29.375rem;
  margin: 0 auto 8.75rem;

  figure {
    display: block;
    margin: 0 0 2.5rem;
    border: .3125rem solid transparent;
    transition: $transition-base;

    img {
      width: 100%;
      height: auto;
      // mix-blend-mode: multiply;
    }
  }

  h3 {
    margin: 0 0 .4rem 2.5rem;
    max-width: 16.25rem;
    font-size: 2.25rem;
    color: $grey-5;
    transition: $transition-base;
  }

  h6 {
    margin: 0 0 0 2.5rem;
    font-size: .875rem;
    letter-spacing: .014285714em;
  }

  &:hover {
    cursor: pointer;

    figure {
      border-color: $primary;
    }

    h3 {
      color: $primary;
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: 100%;
    margin: 0 0 5rem;

    h3 {
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(xs) {
    h3,
    h6 {
      margin-left: 0;
    }
  }
}
