.page-home {
  .page-wrapper {
    padding: 0;
    width: 100%;
  }

  .page-content {
    padding: 0;
  }
}


.home-contact {
  position: fixed;
  top: 50%;
  right: 0;
  width: 10rem;
  transform: rotate(90deg) translateX(50%);
  transform-origin: 100% 0;
  font-size: .875rem;
  color: $primary;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: $transition-base, right 0s;

  &:hover {
    color: $primary;
  }

  .home & {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  .has-scrollbar & {
    right: 15px;
  }
}
