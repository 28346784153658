.location-location-section {
  @include grid-gutter(.625rem);
  margin-bottom: 6.25rem;

  .container {
    position: relative;
    left: -4.375rem;
  }

  figure {
    margin: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  // Text
  h2 {
    position: relative;
    max-width: 17.5rem;
    margin: 0 0 6.25rem;
    color: $black;

    img {
      position: absolute;
      top: 1.25rem;
      left: 18rem;
      width: 10rem;
      height: auto;
    }
  }

  p {
    max-width: 29.75rem;
    font-weight: $font-weight-light;
  }

  h4 {
    margin: 4rem 0 .5rem;
    font-size: 1.375rem;
    letter-spacing: .022727273em;
  }

  .text-1 {
    padding-left: 3.75rem;
  }

  @include media-breakpoint-down(lg) {
    .text-1 {
      padding-left: 1.875rem;
    }

    h2 {
      margin-bottom: 2.5rem;

      img {
        top: -2.75rem;
        left: 15rem;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .container {
      left: auto;
    }

    .text-1 {
      padding-left: 0;
    }

    h2 {
      margin-top: 5rem;
    }

    p {
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    h2 {
      margin-top: 2.5rem;
      max-width: 100%;

      img {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 5rem;

    h2 {
      margin-bottom: 1rem;
    }

    h4 {
      margin-top: 2.5rem;
    }
  }
}


.neighbours-list {
  @include list-unstyled;
  display: flex;
  flex-wrap: wrap;
  max-width: 26.875rem;
  margin: 0 -.3125rem;

  li {
    flex: 0 0 auto;
    width: percentage(1 / 3);
    padding: 0 .3125rem;
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;

    li {
      width: percentage(1 / 5);
    }
  }

  @include media-breakpoint-down(sm) {
    li {
      width: percentage(1 / 4);
    }
  }

  @include media-breakpoint-down(xs) {
    li {
      width: percentage(1 / 3);
    }
  }
}
